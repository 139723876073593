import React, { Component } from "react";
import { NavLink } from 'react-router-dom';


import singleTrip from "../assets/img/jet-img/watersportcarpackage1.webp";
import islandHopping from "../assets/img/jet-img/watersportcarpackage2.webp";
import carRight from "../assets/img/jet-img/series2WaterSportCar.webp";
import jetVideo1 from "../assets/img/jet-img/jetVideo2.mp4";
import jetVideo2 from "../assets/img/jet-img/jetVideo3.mp4";

const Packages = () => {
  
  return (
    <>
      <div id="package" className="container py-5 package-section">

        <div className="row">
          <div className="col-12 text-center">
            <h3>BOOK OUR TOUR</h3>
          </div>
        </div>

        <div className="packageFlex">
          <div className="packageWrap">

            <div>
              <img src={singleTrip} alt="Water Sport Car In Langkawi"></img>
              <div className="package-container text-center">
                <h5 className="text-white mb-0">Single Tour</h5>
                <p className="text-white mb-2">(30 minutes)</p>
                <p className="text-white mb-2" style={{fontSize:"24px"}}><strong>RM1200</strong></p>
                <NavLink to="/booking">
                  <button>BOOK NOW</button>
                </NavLink>
              </div>
            </div>

            <div>
              <img src={islandHopping} alt="Water Sport Car In Langkawi"></img>
              <div className="package-container text-center">
                <h5 className="mb-3">Island Hopping Tour</h5>
                <NavLink to="/booking">
                  <button disabled>Coming Soon</button>
                </NavLink>
              </div>
            </div>

          </div>
        </div>

      </div>
    </>
  );

};

export default Packages;