import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

import SquareLogo from '../assets/img/logo.png';

const Footer = () => {
    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    };
  
  return (
    <>
      <section className="footer-section" style={{marginTop:"50px"}}>
        <div className="container py-5">
          <div className="row">
              <div className="col-lg-4 text-center text-lg-left">
                <img className="footer-logo" src={SquareLogo}></img>
              </div>
              <div className="col-lg-4 text-center text-lg-left">
                <h5 className="my-3">Explore More</h5>
                <p className="mb-2" style={{fontSize:"14px"}}>
                  <a onClick={() => scrollToSection('package')}>Our Tour</a>
                </p>
                <p className="mb-2" style={{fontSize:"14px"}}>
                  <a onClick={() => scrollToSection('aboutus')}>About Us</a>
                </p>
                <p className="mb-2" style={{fontSize:"14px"}}>
                  <a href="/booking">Book Now</a>
                </p>
              </div>
              <div className="col-lg-4 text-center text-lg-left">
                <h5 className="my-3">Contact Us</h5>
                <p className="mb-2" style={{fontSize:"14px"}}>
                  <a className="" href="https://wa.link/vd7zn3" target="_blank"><i className="fa-brands fa-whatsapp mr-2"></i>+6012-7733649</a>
                </p>
                <p className="mb-2" style={{fontSize:"14px"}}>
                  <a href="mailto:booking@epicjet.com.my" target="_blank"><i className="fa-solid fa-envelope mr-2"></i>booking@epicjet.com.my</a>
                </p>
              </div>
          </div>

        </div>
        <div className="row" style={{backgroundColor:"#2D2D2D"}}>
          <div className="col-12 mt-3 text-center">
            <p className="copyright text-white" style={{fontSize:"12px"}}>Copyright © {(new Date().getFullYear())} Epic Jet Holiday.<br></br>All Rights Reserved.</p>
          </div>
        </div>
      </section>
    </>
  );

};

export default Footer;