// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import SquareLogo from './assets/img/logo.png';
import FooterLogo from './assets/img/footer-logo.png';
import series1 from './assets/img/jet-img/series1WaterSportCar.webp';
import series2 from './assets/img/jet-img/series2WaterSportCar.webp';

// Components
import ContactUsIcon from "./components/contact-us-icon";
import Footer from './components/footer';

// Pages
import Home from "./pages/home";
import Booking from "./pages/booking";


function App() {
  const [isOpen, setIsOpen] = useState(false);

  const openSidebar = () => {
    setIsOpen(true);
  };

  // Function to close the sidebar
  const closeSidebar = () => {
    setIsOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollAndClose = (sectionId) => {
    scrollToSection(sectionId);
    closeSidebar();
  };
  return (

    <Router>
      <ContactUsIcon/>

      <section className="header-section">

        <div className="container px-0 desktop-md">
          <div className="row py-3">

            <div className="col-7 px-0">
              <button className="btn" onClick={() => scrollToSection('aboutus')}>
                <p className="text-white mb-0" style={{fontSize:"14px",fontWeight:"700"}}>HOME</p>
              </button>
              <button className="btn" onClick={() => scrollToSection('package')}>
                <p className="text-white mb-0" style={{fontSize:"14px",fontWeight:"700"}}>OUR TOUR</p>
              </button>
              <button className="btn" onClick={(e) => {
                window.location.href= "/booking";
              }}>
                <p className="text-white mb-0" style={{fontSize:"14px",fontWeight:"700"}}>BOOK NOW</p>
              </button>
            </div>

            <div className="col-5 px-0 text-right">
              <button className="btn action-button" onClick={(e) => {
                window.location.href= "/booking";
              }}>
                <p className="mb-0" style={{fontSize:"14px",fontWeight:"700"}}><i className="fa-solid fa-user mr-2"></i>LOGIN</p>
              </button>
            </div>

          </div>
        </div>

        <div className="container px-0 mobile-md">
          <div className="row py-3">

            <div className="col-6 px-0">
              <button className="btn p-0" onClick={(e) => {
                window.location.href= "/";
              }}>
                <img className="header-logo" src={SquareLogo}></img>
              </button>
            </div>

            <div className={`col-6 px-0 text-right ${isOpen ? 'open' : ''}`}>
              <button className="btn p-0 mx-2" onClick={openSidebar}>
                <p className="header-menu-icon text-white mb-0"><i className="fa-sharp fa-solid fa-bars"></i></p>
              </button>
            </div>

          </div>
        </div>


        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
          <div className='sidebar-top'>
            <div className="logo-section">
              <button className="btn p-0" onClick={(e) => {
                window.location.href= "/";
              }}>
                <img className="header-logo mt-3" src={SquareLogo}></img>
              </button>
            </div>
            <div onClick={closeSidebar} className='closeIcon'>&#x2715;</div>
          </div>

          
          <div className="menu-nav-container">
              <button className="btn" onClick={() => scrollToSection('aboutus')}>
                <p className="header-menu-nav mb-0">ABOUT US</p>
              </button>
              <button className="btn" onClick={() => scrollToSection('package')}>
                <p className="header-menu-nav mb-0">OUR TOUR</p>
              </button>
              <button className="btn" onClick={(e) => {
                window.location.href= "/booking";
              }}>
                <p className="header-menu-nav mb-0">BOOK NOW</p>
              </button>
            </div>
            <div class="user-action-section">
                <button className="btn p-0" onClick={(e) => {
                  window.location.href= "/";
                }}>
                  <p className="header-menu-nav mb-0 register-btn">REGISTER</p>
                </button>
            </div>
        </div>
        
      </section>

      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/booking" element={<Booking/>}/>
      </Routes>

      <Footer/>
      
    </Router>
  );
}

export default App;